
import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import { useContext,useEffect,useState } from "react";
import {Context} from "../../context";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme } from '@mui/material/styles';
import { MuiTelInput } from 'mui-tel-input'
import { Helmet } from 'react-helmet';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import "./style.css";
import 'dayjs/locale/ru';

const AddChampionat = (props) => {
    const contextValue=useContext(Context)
    const [select_championat_type, setSelectChampionatType] =useState(0)
    const [championat_types, setChampionatTypes] =useState([])
    const [select_championat_style, setSelectChampionatStyle] =useState(0)
    const [championat_styles, setChampionatStyles] =useState([])
    const [treners, setTreners] =useState([])
    const [openList, setOpenList] =useState([])
    const [defaultInputValue, setDefaultInputValue] =useState("")
    
    const [options, setOptions] = useState([]);
    const [values,setValues] = useState ({
        school_name:contextValue.school.school_name,
        championat_name:'',
        school_id:contextValue.school_id,
        championat_start_date:'',
        championat_end_date:'',
        championat_final_date_order:'',
        championat_type_id:select_championat_type,
        championat_style_id:select_championat_style,
        championat_adress:contextValue.school.school_adress,
        championat_phone:'',
        main_sud:'',
        main_secret:''
    })
    
    let can=true;

    useEffect(()=>{
        console.log(contextValue)
        console.log(values)
    },[values])

    useEffect(()=>{

      if(contextValue.school_id!==1)  setDefaultInputValue(`${JSON.parse(values.championat_adress)?.data?.country!==null?JSON.parse(values.championat_adress)?.data.country:""}${JSON.parse(values.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(values.championat_adress)?.data.region_with_type}`:""}${JSON.parse(values.championat_adress).data?.city!==null?`, ${JSON.parse(values.championat_adress).data.city}`:""}${JSON.parse(values.championat_adress)?.data?.street_with_type!==null?`, ${JSON.parse(values.championat_adress)?.data?.street_with_type}`:""}${JSON.parse(values.championat_adress)?.data?.house_type!==null?`, ${JSON.parse(values.championat_adress)?.data?.house_type}`:""}${JSON.parse(values.championat_adress)?.data?.house!==null?`,${JSON.parse(values.championat_adress)?.data?.house}`:""}`)
    },[values.championat_adress])

    useEffect(()=>
    {
      axios.get('/api/championats/types')
      .then(res=>{
        console.log(res.data)
        setChampionatTypes(res.data)})
      .catch(err=>console.log(err)) 
    },[]);
    useEffect(()=>
    {
      axios.get('/api/championats/styles')
      .then(res=>{setChampionatStyles(res.data)})
      .catch(err=>console.log(err)) 
    },[]);  

    useEffect(()=>
        {
            axios.get(`/api/treners/my/${contextValue.school_id}`)
            .then(res=>{
              setTreners(res.data)
            })
            .catch(err=>console.log(err)) 
        },[]);

    const handleChangeType = (event) => {
        
        setSelectChampionatType(event.target.value)
        setValues({...values, 
            championat_type_id:event.target.value,
            championat_type_name:championat_types?.find(x=>x.championat_type_id===event.target.value)?.championat_type_name
        })
      };
      const handleChangeStyle = (event) => {
        setSelectChampionatStyle(event.target.value)
        setValues({...values, 
            championat_style_id:event.target.value,
            championat_style_name:championat_styles?.find(x=>x.championat_style_id===event.target.value)?.championat_style_name
        })
      };

    function handleSubmit()
    {
            can=true;
            checkInput();
            
            if(!open && can)
            {
                setOpen(true)
                axios.post("/api/championats/add",values)
                    .then(res=>{
                        if(res.data.Status==="Соревнование добавлено")
                        {                            
                            contextValue.setTypeMsg("success")
                            contextValue.setMsg("Соревнование добавлено")
                            contextValue.setOpenAlert(true)                            
                            setTimeout(props.setActive(false), 10000);                         
                        }
                        else alert(res.data.Error);
                        setOpen(false)  
                    })
                    .catch(err =>alert(err));
            }
            else return false;            
                
    }
    const handleInputChange = async (event, value) => {
        setDefaultInputValue(value)
        setOpenList(false)
        if (value) {
          let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + `0bcf160322fba45f1c4c583a62aafa04057c0629`
            },
            body: JSON.stringify({query: value})
        }
          try {
            const response = await fetch(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,options).then(x => x.json());
            console.log(response)
            if(response.suggestions.length>0) setOptions(response.suggestions.map(item=>item));
          } catch (error) {
            console.error(error);
          }
        } else {
          setOptions([]);
        }
        setOpenList(true)
      };
      useEffect(()=>console.log(options),[options])

      function checkInput()
      {
           if(values.championat_type_id==='0')
           {
               can=false;
               contextValue.setMsg("Не выбран тип соревнования") 
              
           }
           else if(values.championat_name==='')
           {
               can=false;
               contextValue.setMsg("Введите название соревнования")               
           }
           else if(values.championat_start_date==='')
           {
               can=false;
               contextValue.setMsg("Введите дату начала соревнования")               
           }
           else if(values.championat_end_date==='')
           {
               can=false;
               contextValue.setMsg("Введите дату окончания соревнования")               
           }

           else if(values.championat_final_date_order==='')
           {
               can=false;
               contextValue.setMsg("Введите дату окончания времени подачи заявки")               
           }
           else if(values.main_sud==='')
            {
                can=false;
                contextValue.setMsg("Главный судья не выбран")               
            }
            else if(values.main_secret==='')
                {
                    can=false;
                    contextValue.setMsg("Главный секретарь не выбран")               
                }
           
       if(!can)
       {
        contextValue.setTypeMsg("error")
        contextValue.setOpenAlert(true)
    }
   }
   const theme = createTheme();
   theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
  };
  const [open, setOpen] = useState(false);     
    return (           
        <div className="bg-white">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <form onSubmit="return false" >
            <Helmet>
                <title>Новое соревнование</title>
                <meta name="description" content="Регистрация спортивного мероприятия" />
            </Helmet> 
                        <Box
                            sx={{
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            >              
                       
                       <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="championat-type-lable">Ранг соревнования</InputLabel>
                        <Select
                            labelId="championat-type-lable"
                            id="championat-type-select"
                            label="Ранг соревнования"
                            value={select_championat_type}
                            onChange={handleChangeType}
                            >
                                {championat_types?.map((championat_type,i)=>
                                    (
                                        <MenuItem value={championat_type?.championat_type_id}>{championat_type?.championat_type_name}</MenuItem>
                                    ))}
                        </Select>
                        <FormHelperText>Выберите ранг соревнования</FormHelperText>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="championat-style-lable">Тип соревнования</InputLabel>
                        <Select
                            labelId="championat-style-lable"
                            id="championat-style-select"
                            label="Ранг соревнования"
                            value={select_championat_style}
                            onChange={handleChangeStyle}
                            >
                                {championat_styles?.map((championat_style,i)=>
                                    (
                                        <MenuItem value={championat_style?.championat_style_id}>{championat_style?.championat_style_name}</MenuItem>
                                    ))}
                        </Select>
                        <FormHelperText>Выберите тип соревнования</FormHelperText>
                        </FormControl>
                        <TextField id="outlined-basic" label="Название" variant="outlined" name="championst_name" onChange={e=>setValues({...values, championat_name:e.target.value})}/>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker variant="outlined" label="Начало соревнований" onChange={(x, event) => setValues({...values, championat_start_date:`${x.$y}-${x.$M+1}-${x.$D}`})}/>
                            <DatePicker variant="outlined" label="Окончание соревнований" onChange={(x, event) => setValues({...values, championat_end_date:`${x.$y}-${x.$M+1}-${x.$D}`})}/>
                            <DatePicker variant="outlined" label="Срок подачи заявок" onChange={(x, event) => setValues({...values, championat_final_date_order:`${x.$y}-${x.$M+1}-${x.$D}`})}/>
                        </LocalizationProvider>
                        <Autocomplete
                            disableClearable
                            freeSolo
                            open={openList}
                            options={options}
                            
                            inputValue={defaultInputValue }
                            onInputChange={handleInputChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Место проведения" variant="outlined" name="championat_adress"/>)}
                            getOptionLabel={(option) =>
                                {if(option?.data!==undefined)
                                   return `${option?.data?.country!==null?option?.data.country:""}${option?.data?.region_with_type!==null?`, ${option?.data.region_with_type}`:""}${option?.data?.city!==null?`, ${option?.data.city}`:""}${option?.data?.street_with_type!==null?`, ${option?.data?.street_with_type}`:""}${option?.data?.house_type!==null?`, ${option?.data?.house_type}`:""}${option?.data?.house!==null?`,${option?.data?.house}`:""}`
                                    else return ""}}
                            onChange={(event, value)=>
                                {
                                    setValues({...values,championat_adress:JSON.stringify(value)})}
                                    // setDefaultInputValue()
                                }
                            filterOptions={(options, { inputValue }) =>
                                options.length>0 && options?.filter((option) => {
                                const inputWords = inputValue.toLowerCase().replace(/[.,%]/g, '').split(" ");
                                const optionWords = JSON.stringify(option.data).toLowerCase().split(" ");
                                console.log(inputWords)
                                return inputWords.every((word) =>
                                optionWords.some((optionWord) => optionWord.includes(word))
                                );
                            })
                            }
                            />
                        <MuiTelInput label="Телефон для связи" defaultCountry='RU' value={values.championat_phone} onChange={(newPhone)=>setValues({...values, championat_phone:newPhone})} />
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="main_sud">Главный судья</InputLabel>
                        <Select
                            labelId="main_sud"
                            label="Главный судья"
                            onChange={(e)=>setValues({...values,main_sud:e.target.value})}
                            >
                                {treners?.filter(x=>x.archive===0 && x.sud===1)?.map((trener,i)=>
                                    (
                                        <MenuItem value={trener?.trener_id}>{`${trener.trener_family} ${trener.trener_name} ${trener.trener_otchestvo}`}</MenuItem>
                                    ))}
                        </Select>
                        <FormHelperText>Выберите главного судью</FormHelperText>
                        </FormControl>
                        
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="main_secret">Главный секретарь</InputLabel>
                        <Select
                            labelId="main_secret"
                            label="Главный секретарь"
                            onChange={(e)=>setValues({...values,main_secret:e.target.value})}
                            >
                                {treners?.filter(x=>x.archive===0 && x.secret===1)?.map((trener,i)=>
                                    (
                                        <MenuItem value={trener?.trener_id}>{`${trener.trener_family} ${trener.trener_name} ${trener.trener_otchestvo}`}</MenuItem>
                                    ))}
                        </Select>

                        <FormHelperText>Выберите главного секретаря</FormHelperText>
                        </FormControl>
                        </Box>
                        <Box
                            sx={{
                                
                                paddingInline:'10px',
                                mt:1,
                                mb:-1,
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(2, 1fr)'
                            }}
                            >

                        <Button                
                            variant="contained"
                            key="addsosav"
                            disabled={open}
                            onClick={handleSubmit}
                        >Добавить </Button>


                        <Button                
                            variant="contained"
                            key="cancel"
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button>
                        
                        
                    </Box>
                       
            </form>
        </div>

      );
}
 
export default AddChampionat;