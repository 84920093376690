
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import logo from "./../../img/Logo.png"
import { makeStyles } from '@mui/styles';
import {Context} from "../../context";
import {useContext} from "react";
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const theme = createTheme({
  shadows: ["none"]
});

const Item = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  minWidth:"400px",
  p:"0px",
  transition: 'background-color 0.3s ease', // Для плавного перехода цвета
  '&:hover': {
    backgroundColor: '#ADD8E6', // Светлоголубой цвет
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });



  theme.typography = {
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
  };

const Championat = (props) => {
    const contextValue=useContext(Context)
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const useStyles = makeStyles({
        demowrap:{
            position:'relative',
            '&:before':{
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  filter: 'blur(3px) grayscale(50%)',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0.2,
                  backgroundImage: `url(${logo})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50% 0',
                  backgroundSize: 'cover'
            }
        }
    })
    const classes = useStyles()

    function deleteChampionat(id)
    {            
        axios.post(`/api/championats/championat/delete/${id}`)
        .then(res=>{
            console.log(res)
            if(res.data.Status==="Соревнование удалено")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Соревнование удалено`)
              contextValue.setOpenAlert(true)
              setOpen(false);
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
    } 

    
    
    function handleClickOpen() {
        setOpen(true);
      };
    
      const handleClose = () => {        
          setOpen(false);
      };

      const GoToChampionst =()=>
      {
        contextValue.setChampionatID(props.championat_id);
        localStorage.setItem('championatID', JSON.stringify(props.championat_id))
        localStorage.setItem('championat_type_name', JSON.stringify(props.championat_type_name))
        localStorage.setItem('championat_type_id', JSON.stringify(props.championat_type_id))
        localStorage.setItem('championat_style_name', JSON.stringify(props.championat_style_name))
        localStorage.setItem('championat_name', JSON.stringify(props.championat_name))
        localStorage.setItem('championat_start_date', JSON.stringify(props.championat_start_date))
        localStorage.setItem('championat_end_date', JSON.stringify(props.championat_end_date))
        localStorage.setItem('championat_adress', JSON.stringify(props.championat_adress))
        localStorage.setItem('school_name', JSON.stringify(props.school_name))
        navigate("/championat")
      }

      const GoToOrder =()=>
      {
        contextValue.setChampionatID(props.championat_id);
        localStorage.setItem('championatID', JSON.stringify(props.championat_id))
        localStorage.setItem('championat_type_name', JSON.stringify(props.championat_type_name))
        localStorage.setItem('championat_type_id', JSON.stringify(props.championat_type_id))
        localStorage.setItem('championat_style_name', JSON.stringify(props.championat_style_name))
        localStorage.setItem('championat_name', JSON.stringify(props.championat_name))
        localStorage.setItem('championat_start_date', JSON.stringify(props.championat_start_date))
        localStorage.setItem('championat_end_date', JSON.stringify(props.championat_end_date))
        localStorage.setItem('championat_adress', JSON.stringify(props.championat_adress))
        localStorage.setItem('order_archive', JSON.stringify(props.archive))
        contextValue.setOrderMemberID(null) 
        navigate("/order")
      }


    return (
        <>
           <React.Fragment>            
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Удалить соревнование?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Информация о соревновании: "{props.championat_name}" будет удалена и более недоступна.
                  Все заявки на это соревнование будут удалены.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>deleteChampionat(props.championat_id)}>Да</Button>
                <Button onClick={handleClose}>Нет</Button>                
              </DialogActions>
            </Dialog>
          </React.Fragment>

       {props.view!=="Список"?
        <Card  className={classes.demowrap} 
        sx={{
          padding:0,             
          maxWidth: 320,
          minWidth:320,
          minHeight: 400,
          maxHeight: 400,
          display: "flex",
        flexDirection: "column"     
        }}>
            
            
            <CardActionArea 
            onClick={GoToChampionst}>
              <CardHeader  sx={{
                maxHeight:'130px',
                display: { xs: 'flex', md: 'flex' },
                overflow: 'auto'}}
                titleTypographyProps={props.championat_name.length>30?{variant:'body2'}:{variant:'h5'}}        
                title= {props.championat_name}                
                subheaderTypographyProps={{variant:'body2' }}   
                subheader={props.school_name}   
            />
             <CardContent disableSpacing sx={{ 
                maxHeight: 250,
                justifyContent:'center',
                overflow: 'auto'
                }}>
                <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Ранг соревнования: </b>{props.championat_type_name}
                </Typography>
                <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Тип соревнования: </b>{props.championat_style_name}
                    </Typography>
                <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Место проведения: </b>{`${JSON.parse(props.championat_adress)?.data?.country!==null?JSON.parse(props.championat_adress)?.data.country:""}${JSON.parse(props.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(props.championat_adress)?.data.region_with_type}`:""}${JSON.parse(props.championat_adress)?.data?.city!==null?`, ${JSON.parse(props.championat_adress)?.data.city}`:""}${JSON.parse(props.championat_adress)?.data?.street_with_type!==null?`, ${JSON.parse(props.championat_adress)?.data?.street_with_type}`:""}${JSON.parse(props.championat_adress)?.data?.house_type!==null?`, ${JSON.parse(props.championat_adress)?.data?.house_type}`:""}${JSON.parse(props.championat_adress)?.data?.house!==null?`,${JSON.parse(props.championat_adress)?.data?.house}`:""}`}
                    </Typography>
                <Typography sx={{ my:1,
                        textAlign:'left',
                        color: 'inherit',
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Даты:</b> {props.championat_start_date!==props.championat_end_date?`${props.championat_start_date} - ${props.championat_end_date}`:`${props.championat_start_date}`}
                </Typography>
                <Typography sx={{ my:1,
                        textAlign:'left',
                        color: 'inherit',
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Срок подачи:</b> {props.championat_final_date_order}
                </Typography>
                
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing sx={{padding:0,margin:0, mt: "auto" }}>

            <Tooltip title="Страница соревнования">
                <IconButton                      
                      style={{display:'flex',maxHeight: '50px', minHeight: '30px',borderRadius:0}}>
                    <EmojiEventsOutlinedIcon
                    onClick={GoToChampionst}/>
                </IconButton>
                </Tooltip>    
            <Tooltip title="Заявка">
            <IconButton key="editOrder"
                      onClick={GoToOrder}
                      fullWidth
                      style={{display:'flex',maxHeight: '50px', minHeight: '30px',borderRadius:0}}>
                    <FormatListBulletedOutlinedIcon />
                </IconButton>
                </Tooltip>
                {props.school_id===contextValue.school_id &&
                <Tooltip title="Удалить соревнование">
                <IconButton key="delOrder"
                      fullWidth
                      style={{display:'flex',maxHeight: '50px', minHeight: '30px',borderRadius:0}}>
                    <DeleteOutlineOutlinedIcon
                    onClick={handleClickOpen}/>
                </IconButton>
                </Tooltip>}
             </CardActions>
            </Card>
            :
            <Item sx={{my:3}} fullWidth elevation={1} onClick={GoToChampionst}>
              <Grid container alignItems={"stretch"} columns={14} spacing={1}>

              <Grid item sx={{borderRight:"1px solid"}} 
              xs={14} 
              sm={8} 
              display="flex"
              justifyContent="center"
              flexDirection="column">                                   
                                        
              <Typography variant={props.championat_name.length>30?'h6':'h5'} display="block" gutterBottom sx={{
                  fontFamily: 'Poppins',
                  whiteSpace: "pre-wrap"                                            
                  }} align="center">
                    {props.championat_name}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom sx={{
                  fontFamily: 'Poppins',
                  whiteSpace: "pre-wrap"                                            
                  }} align="center">
                    {props.school_name}
                  </Typography>            
              </Grid>
              
              <Grid item sx={{
                borderRight:"1px solid",
                display:{xs:"none", md:"flex"}
              }} 
              xs={4} 
              sm={4} 
              
              justifyContent="center"
              flexDirection="column">                                   
                                        
                                        <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Ранг соревнования: </b>{props.championat_type_name}
                </Typography>
                <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Тип соревнования: </b>{props.championat_style_name}
                    </Typography>
                <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Место проведения: </b>{`${JSON.parse(props.championat_adress)?.data?.country!==null?JSON.parse(props.championat_adress)?.data.country:""}${JSON.parse(props.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(props.championat_adress)?.data.region_with_type}`:""}${JSON.parse(props.championat_adress)?.data?.city!==null?`, ${JSON.parse(props.championat_adress)?.data.city}`:""}${JSON.parse(props.championat_adress)?.data?.street_with_type!==null?`, ${JSON.parse(props.championat_adress)?.data?.street_with_type}`:""}${JSON.parse(props.championat_adress)?.data?.house_type!==null?`, ${JSON.parse(props.championat_adress)?.data?.house_type}`:""}${JSON.parse(props.championat_adress)?.data?.house!==null?`,${JSON.parse(props.championat_adress)?.data?.house}`:""}`}
                </Typography>
                <Typography sx={{ 
                        textAlign:'left',
                        color: 'inherit',
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Даты:</b> {props.championat_start_date!==props.championat_end_date?`${props.championat_start_date} - ${props.championat_end_date}`:`${props.championat_start_date}`}
                </Typography>
                <Typography sx={{ 
                        textAlign:'left',
                        color: 'inherit',
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Срок подачи:</b> {props.championat_final_date_order}
                </Typography> 
              </Grid>
              <Grid item 
              xs={14} 
              sm={1} 
              sx={{ 
                flexDirection:{xs:"row",sm:"column"}
              }}
              justifyContent="center">  
              <Tooltip title="Страница соревнования">
                <IconButton                      
                      style={{display:'flex',maxHeight: '50px', minHeight: '30px',borderRadius:0}}>
                    <EmojiEventsOutlinedIcon
                    onClick={GoToChampionst}/>
                </IconButton>
                </Tooltip>                                 
              <Tooltip title="Заявка">
            <IconButton key="editOrder"
                      onClick={GoToOrder}
                      style={{display:'flex',maxHeight: '50px', minHeight: '30px',borderRadius:0}}>
                    <FormatListBulletedOutlinedIcon />
                </IconButton>
                </Tooltip>
                {props.school_id===contextValue.school_id &&
                <Tooltip title="Удалить соревнование">
                <IconButton key="delOrder"
                      style={{display:'flex',maxHeight: '50px', minHeight: '30px',borderRadius:0}}>
                    <DeleteOutlineOutlinedIcon
                    onClick={handleClickOpen}/>
                </IconButton>
                </Tooltip>}         
              </Grid>
             </Grid> 
            </Item>
          }
        </>       
         );
    
}
 
export default Championat;