import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import FormDialog from "../ModalWindow/Dialog/FormDialog"
import AddSud from "../components/addSud/AddSud";
import {Helmet} from "react-helmet";
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logo from "./../img/Logo.png"

import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const ChampionatSuds = (props) => {
        const contextValue=useContext(Context)
        const[editSud,setEditSud]=useState(false)
        const [selectSud,setSelectSud]=useState(null)
        const [print,setPrint]=useState(false)
        const [open, setOpen] = useState(false);  
        

        useEffect(()=>{
                if(selectSud!==null){
                contextValue.setChampionatSudID(selectSud?.championat_sud_id)
                setEditSud(true)}
        },[selectSud])
       
        function deleteSud(championat_sud_id)
        {            
            axios.post(`/api/suds/delete/${championat_sud_id}`)
            .then(res=>{
                console.log(res.data.Status)
                if(res.data.Status==="Судья удален")
                {
                  contextValue.setTypeMsg("success")
                  contextValue.setMsg(`Судья удален`)
                  contextValue.setOpenAlert(true)
                }
                else console.log(res.data.Status);
            })
            .catch(err =>alert(err))
        } 
        useEffect(()=>{print?(handlePrint()):setOpen(false)},[print])
        const handlePrint = useReactToPrint({
                content: () => componentRef.current,
                pageStyle: "@page { size: auto; margin: 0mm; }",
                contentStyle: { transform: 'scale(0.8)', transformOrigin: 'top left' },
                onBeforeGetContent:()=>{setOpen(true)},
                onAfterPrint:()=>{setPrint(false)}
              });
              
        const componentRef = useRef();
        
    return (
        <>
                        {editSud && <FormDialog title={selectSud!==null?'Изменение судьи':'Добавление судьи'} width='md' active={editSud} setActive={setEditSud} children={AddSud} orders={{suds:props.orders,selectSud:selectSud}}/>}
                        <Helmet>
                                <title>Заявка</title>
                                <meta name="description" content="Редактирование заявки" />
                        </Helmet> 
                        <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                        >
                        <CircularProgress color="inherit" />
                        </Backdrop>
                        <Button onClick={()=>{contextValue.setChampionatSudID(null);setSelectSud(null);setEditSud(true)}}>Добавить судью</Button>       
                        <Button onClick={()=>{setPrint(true)}}>Печать</Button>
                        <Box alignItems={'center'} width={'100%'} sx={{p:3 }} ref={componentRef} pageStyle={{size:'landscape'}}>
                        {print&&
                        <><TableContainer sx={{ my:1}} >
                        <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                                      '& .MuiTableCell-sizeSmall': {
                                      padding: '1px',
                                      },
                                }}
                              > 
                               <TableBody> 
                                <TableRow>
                                  <TableCell align='center' style={{width:100,border:0}} rowSpan={2}>
                                    <img width={100} src={logo}></img> 
                                  </TableCell>
                                  <TableCell colSpan={4} style={{width: '90%', border:0}} align='center'>
                                      <Typography fontSize={24}><strong>{contextValue.championat.championat_name}</strong></Typography>
                                  </TableCell>
                                </TableRow>
                                    <TableRow>
                                    <TableCell style={{ border:0}} align='center'>
                                      <Typography fontSize={10}>{contextValue.championat.championat_start_date}-{contextValue.championat.championat_end_date}</Typography>
                                    </TableCell>
                                    <TableCell style={{width: '50%',border:0}} align='center'>
                                      {/* <Typography fontSize={10}>{contextValue.championat.championat_start_date}-{contextValue.championat.championat_end_date}</Typography> */}
                                    </TableCell>
                                    <TableCell  align='center' style={{ border:0}}>
                                      <Typography fontSize={10}>{contextValue.championat.championat_adress}</Typography>
                                    </TableCell>
                                    </TableRow>
                                </TableBody>
                                </Table>
                                </TableContainer>  
                        <TableContainer>
                        <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                                      '& .MuiTableCell-sizeSmall': {
                                      padding: '1px',
                                      },
                                      }}
                              > 
                              <TableHead>
                                <TableRow>
                                  <TableCell rowSpan={1} sx={{ border: 1 }}>№</TableCell>         
                                  <TableCell align="center"
                                  rowSpan={1} sx={{ border: 1 }} >ФИО</TableCell>
                               <TableCell align="center"
                                  rowSpan={1} sx={{ border: 1 }}>Должность</TableCell>
                                       <TableCell align="center"
                                rowSpan={1} sx={{ border: 1 }}>Категория</TableCell>
                                       <TableCell align="center"
                                 rowSpan={1} sx={{ border: 1 }}>Регион</TableCell>
                                       <TableCell align="center"
                                  rowSpan={1} sx={{ border: 1 }}>Оценка</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {props.orders?.filter(x=>x.sud_role_id!==0)?.map((s,i)=>(
                                    <TableRow
                                    key={s.order_member_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                                  >
                                   <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{i+1}</p>           
                                  </TableCell>      
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.FIO}</p>           
                                  </TableCell>   
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.sud_role_name}</p>           
                                  </TableCell> 
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.sud_categ_name}</p>           
                                  </TableCell>
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.region}</p>           
                                  </TableCell>
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.score}</p>           
                                  </TableCell>
                                    </TableRow>))}          
                                  </TableBody>
                            </Table>
                          </TableContainer>
                          <TableContainer sx={{ my:1, breakAfter:'page'}} >
                  <Table style={{ width: "100%"}}  aria-label="sticky table" size='small'  sx={{
                            '& .MuiTableCell-sizeSmall': {
                              padding: '1px',
                            },
                          }}
                        > 
                         <TableBody> 
                          <TableRow>
                              <TableCell style={{width:'20%', border:0}} sx={{whiteSpace: "pre"}} align='center'>
                                  {/* <Typography fontSize={10}> Должность, судейская категория </Typography> */}
                              </TableCell>
                              <TableCell style={{width:'30%', border:0}} rowSpan={10} align='center' alignItems='center' alignContent={'center'}>
                                  <Typography fontSize={18}> МП </Typography>
                              </TableCell>
                              <TableCell style={{width:'30%', border:0}} align='center'>
                                  {/* <Typography fontSize={10}> Подпись </Typography> */}
                              </TableCell>
                              <TableCell style={{width:'20%', border:0}} align='center'>
                                  {/* <Typography fontSize={10}> ФИО, регион, ведомство </Typography> */}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ border:0}} align='right'sx={{whiteSpace: "pre"}}>
                                  <Typography fontSize={12}>{`${props.orders[0].sud_role_name}\n${props.orders[0].sud_categ_name}`}</Typography>
                              </TableCell>
                              <TableCell style={{ border:0}} align='left'>
                                  <Typography fontSize={12}>  </Typography>
                              </TableCell>
                              <TableCell style={{ border:0}} align='right' sx={{whiteSpace: "pre"}}>
                                  <Typography fontSize={12}> {props.orders[0].FIO }</Typography>
                              </TableCell>
                              </TableRow>
                            <TableRow>
                              <TableCell style={{ border:0}} align='right'>
                                  <Typography fontSize={12}>{`${props.orders[1].sud_role_name}\n${props.orders[1].sud_categ_name}`}</Typography>
                              </TableCell>
                              <TableCell style={{ border:0}} align='left'>
                                  <Typography fontSize={12}>  </Typography>
                              </TableCell>
                              <TableCell style={{ border:0}} align='right' sx={{whiteSpace: "pre"}}>
                                  <Typography fontSize={12}> {props.orders[1].FIO} </Typography>
                              </TableCell>
                              </TableRow>
                          </TableBody>                    
                     </Table>
                     </TableContainer>
                          </>}
                          </Box>
                          {!print&&<TableContainer component={Paper}>
                          <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                                        '& .MuiTableCell-sizeSmall': {
                                        padding: '1px',
                                        },
                                        }}
                                > 
                                <TableHead>
                                  <TableRow>
                                    <TableCell>№</TableCell>         
                                    <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>ФИО</TableCell>
                                 <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>Должность</TableCell>
                                         <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>Категория</TableCell>
                                         <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>Регион</TableCell>
                                         <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>Оценка</TableCell>
                                        <TableCell width='100' align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}></TableCell>
                                       </TableRow>
                                </TableHead>
                                <TableBody>
                                {props.orders?.filter(x=>x.sud_role_id!==0)?.map((s,i)=>(
                                      <TableRow
                                      key={s.order_member_id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                     <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <p>{i+1}</p>           
                                    </TableCell>      
                                    <TableCell sx={{
                                            whiteSpace: "pre"
                                            }}align="left">
                                            <p>{s.FIO}</p>           
                                    </TableCell>   
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <p>{s.sud_role_name}</p>           
                                    </TableCell> 
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <p>{s.sud_categ_name}</p>           
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <p>{s.region}</p>           
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <p>{s.score}</p>           
                                    </TableCell>
                                    

                                     <TableCell align="center">
                                        <Tooltip title="Редактировать"> 
                                                <IconButton  aria-label="edit" onClick={()=>{setSelectSud(null);setSelectSud(s)}}>
                                        <EditOutlinedIcon />
                                        </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Удалить"> 
                                                <IconButton  aria-label="edit" onClick={()=>{deleteSud(s.championat_sud_id)}}>
                                                <CloseOutlinedIcon />
                                        </IconButton>
                                        </Tooltip>
                                        </TableCell>
                                      </TableRow>))}          
                                    </TableBody>
                              </Table>
                            </TableContainer> }
                            
        </>

        
     )
}
 
export default ChampionatSuds;