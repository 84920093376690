import { useEffect, useState } from "react";
import {Context} from "../context";
import {useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Divider from '@mui/material/Divider';
import AvatarPreviewDialog from './AvatarPreviewDialog';
import {Avatar} from '@mui/material';
import {CssBaseline, List, ListItem, ListItemIcon } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const Back = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  
const SportsmenPage = (props) => {
    const contextValue=useContext(Context)
    const {id}=useParams(); 
    const [selectedRazryad, setSelectedRazryad] =useState(null) 
    const [selectedDate, setSelectedDate] =useState(null)
    const [razryads, setRazryads] =useState([])
    const [sostav, setSostav] =useState([])
    const [razryad_types, setRazryadTypes] =useState([])
    const [sportsmen,setSportsmen]=useState({        
      sportsmen_id:"",
      sportsmen_family:'',
      sportsmen_name:'',
      sportsmen_otchestvo:'',
      sportsmen_age:'',
      razryad_name:'',
      trener_id:'',
      sostav_id:'',
      sostav_type_id:'',
      sportsmen_avatar:'',
    })

    const [razryad,setRazryad]=useState({        
        sportsmen_id:'',
        razryad_date_start:null,
        razryad_date_end:"",
        razryad_org:'',
        razryad_prikaz:'',
        razryad_type_id:null
      })

      function ClearRazryad()
      {
        setSelectedDate(null);
        setSelectedRazryad(null)
        setRazryad({...razryad,
            razryad_date_start:null,
            razryad_date_end:"",
            razryad_org:'',
            razryad_prikaz:'',
            razryad_type_id:null})
      }


    const [treners, setTreners] =useState([])
    const [girl,setgirl]=useState(true);

    async function getTreners() {      
        let response = await axios.get(`/api/treners/my/${contextValue.school_id}`);
        let treners = response.data;// Don't need await here
        setTreners(treners.filter(x=>x.archive===0 && x.trener===1));
        // console.log("Тренеры ", {treners})
    };

    async function getRazryads(id) {      
        console.log(id)
        let response = await axios.get(`/api/razryads/get/${id}`);
        let razryads = response.data;// Don't need await here
        setRazryads(razryads);
        // console.log("Разряды ", {razryads})
    };

    async function getRazryadTypes() {      
        let response = await axios.get(`/api/razryads/razryad_types`);
        let razryad_types = response.data;// Don't need await here
        setRazryadTypes(razryad_types);
        // console.log("типы разрядов ", {razryad_types})
    };

    function getSportsmen()
    {        
            axios.post(`/api/sportsmens/sportsmen/${contextValue.sportsmen}`,{id})
            .then(res=>{
                console.log(res.data)
            setSportsmen({...sportsmen,
                sportsmen_id:res.data[0].sportsmen_id,
                sportsmen_family:res.data[0].sportsmen_family,
                sportsmen_name:res.data[0].sportsmen_name,                
                sportsmen_gender:res.data[0].sportsmen_gender,
                sportsmen_otchestvo:res.data[0].sportsmen_otchestvo,
                sportsmen_age:res.data[0].sportsmen_age,
                razryad_name:res.data[0].razryad_name,
                trener_id:res.data[0].trener_id,
                sostav_id:res.data[0].sostav_id,
                sostav_type_id:res.data[0].sostav_type_id,
                sportsmen_avatar:`./uploads/sportsmens/${res.data[0].sportsmen_id}/avatar_full.png`,
                sportsmen_avatar_crop:`./uploads/sportsmens/${res.data[0].sportsmen_id}/avatar_crop.png`
            })
            res.data[0].sportsmen_gender==='ж'?setgirl(true):setgirl(false)            
            setRazryad({...razryad,sportsmen_id:res.data[0].sportsmen_id})
            getRazryads(res.data[0].sportsmen_id);
            getSostav(res.data[0].sostav_id)
        })
        .catch(err =>alert(err))
        
      }
      
      function getSostav(sostav_id)
      {
        if(sostav_id!==null)
        axios.get(`/api/sostavs/sostav/${sostav_id}`)
            .then(res=>{
                console.log(res.data)
                setSostav(res.data)
            }).catch(err =>alert(err))
            else setSostav([])
      }
      useEffect(()=>
        {
           
            ClearRazryad()

        },[sportsmen.sportsmen_id])

    useEffect(()=>
    {
        if(contextValue.sportsmen!==null)
        {
            getSportsmen();
            getTreners();
            getRazryadTypes();
        } 
    },[contextValue.sportsmen, contextValue.setOpenAlert])

    useEffect(()=>
    {
        if(contextValue.sportsmen!==null)
        {
            getRazryads(contextValue.sportsmen);
        } 
    },[contextValue.setOpenAlert])

    useEffect(()=>{
        if(razryad.razryad_date_start!=null && razryad.razryad_type_id!==null)
        {
            let date_end=new Date(razryad.razryad_date_start);
            date_end.setFullYear(date_end.getFullYear()+razryad_types[razryad_types.findIndex(x=>x.razryad_id===razryad.razryad_type_id)].razryad_time)
            setRazryad({...razryad,razryad_date_end:date_end})
        }
    },[razryad.razryad_date_start,razryad.razryad_type_id])


    function handleSubmit(event)
    {
        can=true;
        contextValue.setMsg('');
        checkInput();
        if(can)
         {
        event.preventDefault();
        axios.post("/api/sportsmens/sportsmen/update",sportsmen)
        .then(res=>{
            if(res.data.Status==="Спортсмен изменен")
            { 
                contextValue.setTypeMsg("success")
                contextValue.setMsg(`Спортсмен изменен`)
                contextValue.setOpenAlert(true)        
                props.setActive(false)       
            }
            else alert(res.data.Error);
        })
        .catch(err =>alert(err));
    }
    }

    function AddRazryad(event)
    {
        event.preventDefault();
        axios.post("/api/razryads/add/"+sportsmen.sportsmen_id,razryad)
        .then(res=>{
            if(res.data.Status==="Разряд добавлен")
            { 
                contextValue.setTypeMsg("success")
                contextValue.setMsg(`Разряд добавлен`)
                contextValue.setOpenAlert(true)  
            }
            else alert(res.data.Error);
        })
        .catch(err =>alert(err));    
    }

    function deleterazryad(id)
    {
        console.log(id)
        axios.post(`/api/razryads/delete/${id}/${sportsmen?.sportsmen_id}`)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Разряд удален")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Разряд удален`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))            
    }


    let can=true;
    function checkInput()
    {         
         if(sportsmen.sportsmen_family==='')
         {
            contextValue.setMsg("Введите фамилию")
            can=false
         }
         else if(sportsmen.sportsmen_name==='')
         {
            contextValue.setMsg("Введите имя")
            can=false
         }    
         else if(sportsmen.sportsmen_age==='')
         {
            contextValue.setMsg("Введите дату рождения")
            can=false
         }       
         else if(sportsmen.sportsmen_gender==='')
         {
            contextValue.setMsg("Выберите пол ребенка")
            can=false
         }  
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
   
        const [alignment, setAlignment] = useState(sportsmen.sportsmen_gender);
       
        const handleChange = (event, newAlignment) => {
          setAlignment(newAlignment);
          setSportsmen({...sportsmen, sportsmen_gender:newAlignment})
        }
        const handleDateChange = (newValue) => {
            if (newValue) {
                const formattedDate = `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`;
                setSportsmen({ ...sportsmen, sportsmen_age: formattedDate });
            }
        };
        const [preview, setPreview] = useState(null);
      const [open, setOpen] = useState(false);
      const [selectedFile, setSelectedFile] = useState(null);
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(event.target.files[0])
          setPreview(URL.createObjectURL(file));
          setOpen(true);
        }
      };
    
      async function handleDeleteAvatar()
      {
        try {
            await axios.delete(`/avatar_sportsmen_delete/${sportsmen.sportsmen_id}`);
            setPreview(null);
            setSportsmen({...sportsmen,               
                sportsmen_avatar:null,
                sportsmen_avatar_crop:null
            })
        } catch (error) {
            console.error('Ошибка при удалении изображения:', error);
            contextValue.setTypeMsg("error")
            contextValue.setMsg(`Произошла ошибка при удалении изображения`)
            contextValue.setOpenAlert(true)
        }
      }

      useEffect(()=>
        {
            console.log(sportsmen)
            if(sportsmen?.sportsmen_id!=='')
                {
            let avatar = props.orders?.filter(x=>x.sportsmen_id===sportsmen.sportsmen_id)[0]           
            avatar.sportsmen_avatar_crop=sportsmen.sportsmen_avatar_crop
                }
        },[sportsmen.sportsmen_avatar_crop])
      const handleClose = () => {
        setOpen(false);
      };

      const menuItems = [
        { text: 'Личные данные', icon: <AccountCircleIcon /> },
        { text: 'Фото', icon: <AccountBoxOutlinedIcon /> },
        { text: 'Разряд', icon: <WorkspacePremiumOutlinedIcon /> },
        // Добавьте другие пункты меню по необходимости
    ];
    const renderContent = () => {
        switch (selectedItem) {
            case 'Личные данные':
                return  <form onSubmit="return false">           
            <Box
            fullWidth
                sx={{
                    padding:"10px",
                    my:1,
                    display: 'grid',                    
                    gridAutoColumns: {xs:'1fr',md:'2fr'} ,
                    gap: 2,
                    gridTemplateColumns: {xs:'repeat(1, 1fr)',md:'repeat(2, 2fr)'}  ,
                    alignContent:'center',
                    alignItems:'center'               
                }}
                >
                                   
                            <TextField id="outlined-basic" value={sportsmen.sportsmen_family} label="Фамилия" variant="outlined" name="family" onChange={e=>setSportsmen({...sportsmen, sportsmen_family:e.target.value})}/>
                            
                            <TextField id="outlined-basic" value={sportsmen.sportsmen_name} label="Имя" variant="outlined" name="name" onChange={e=>setSportsmen({...sportsmen, sportsmen_name:e.target.value})}/>
    
                            <TextField id="outlined-basic" value={sportsmen.sportsmen_otchestvo} label="Отчество" variant="outlined" name="otchestvo" onChange={e=>setSportsmen({...sportsmen, sportsmen_otchestvo:e.target.value})}/>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker
                                    variant="outlined"
                                    value={dayjs(sportsmen.sportsmen_age)}
                                    label="Дата рождения"
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <ToggleButtonGroup
                                    color="primary"
                                    value={sportsmen.sportsmen_gender}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="Platform"
                                    sx={{my:1,  
                                        display: 'grid',                    
                                        gridAutoColumns: '1fr',
                                        // gap: 2,
                                        gridTemplateColumns: 'repeat(2, 1fr)'            
                                    }}
                                    >
                                    <ToggleButton value='м'>Мальчик</ToggleButton>
                                    <ToggleButton value='ж'>Девочка</ToggleButton>
                            </ToggleButtonGroup>
    
                            <Autocomplete
                                key={sportsmen.sportsmen_id}
                                value={treners.find((t)=>t.trener_id===sportsmen.trener_id)}
                                id="trener"
                                sx={{my:1}}
                                name='selecttrener'
                                freeSolo
                                options={treners}
                                
                                getOptionLabel={(option) =>`${option?.FIO}`}
                                onChange={(event, value) => {value!==null?
                                    setSportsmen({...sportsmen, trener_id:value?.trener_id})
                                        :
                                    setSportsmen({...sportsmen, trener_id:null})
                                }}
                                renderInput={(params) => <TextField {...params} label="Тренер"                        
                                />}
                            />
                            <Button                
                                variant="contained"
                                key="editSportsmen"
                                onClick={handleSubmit}
                            >Сохранить </Button>
                            <Button                
                                variant="contained"
                                key="Cancel"  
                                onClick={()=>props.setActive(false)}
                            >Отмена </Button> 
                            </Box> 
                </form>
            case 'Фото':
                return <>
                {open&&<AvatarPreviewDialog open={open} preview={preview} handleClose={handleClose} type={"sportsmens"} selectedFile={selectedFile} user={sportsmen}/>}
                {!open&&<><input
                accept="image/*"
                style={{ display: 'none' }}
                id="avatar-upload"
                type="file"
                onChange={handleFileChange}
              />
              <Grid container spacing={2}  direction={{xs:"column-reverse",md:"row"}}>
              <Grid item alignContent={'center'} alignItems={'center'} xs={12} md={4}>
              <Avatar onerror={'./uploads/users/no_avatar.jpg'} key={`ua`+contextValue.user_avatar_key}  src={sportsmen.sportsmen_avatar} style={{
                  width: '100px',
                  height: '150px',
                  borderRadius: '0',
                  objectFit: 'cover',
                  filter: 'brightness(1.1) contrast(1.2)',
                  border: '2px solid #ccc',
                }}/>
              </Grid>
              <Grid item alignContent={'center'}  alignItems={'center'} xs={12} md={4}>
              <Avatar onerror={'./uploads/users/no_avatar.jpg'} key={`uac`+contextValue.user_avatar_key} src={sportsmen.sportsmen_avatar_crop} sx={{ width: 100, height: 100, marginTop: 2, borderRadius: 0 }}/>
              </Grid>
              <Grid item alignContent={'center'} alignItems={'center'} xs={12} md={4}>
              <Avatar onerror={'./uploads/users/no_avatar.jpg'} key={`uam`+contextValue.user_avatar_key} src={sportsmen.sportsmen_avatar_crop} sx={{
                  width: '75px',
                  height: '75px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  filter: 'brightness(1.1) contrast(1.2)',
                  border: '2px solid #ccc',
                }}/>
                </Grid>
                <Grid item xs={12}>
              <label htmlFor="avatar-upload">
                <Button sx={{my:1}} fullWidth variant="contained" component="span">
                  Загрузить фото
                </Button>
              </label>
              
              <Button sx={{my:1}} fullWidth variant="contained" component="span" onClick={()=>handleDeleteAvatar()}>
                  Удалить фото
                </Button>
              </Grid>
              </Grid>              
            </>}</>
            
            case 'Разряд':
                return <>
                 <Typography>Добавить разряд</Typography>
                 <Box
                sx={{
                    
                    padding:"10px",
                    my:1,
                    display: 'grid',                    
                    gridAutoColumns: {xs:'1fr',md:'2fr'} ,
                    gap: 2,
                    gridTemplateColumns: {xs:'repeat(1, 1fr)',md:'repeat(2, 2fr)'}  ,
                    alignContent:'center',
                    alignItems:'center'               
                }}
                > 
                                
            <Autocomplete
                disablePortal
                    key={razryad_types.razryad_id}                                
                    value={selectedRazryad}
                    id="razryad_types"
                    name='razryad_types'
                    
                    options={razryad_types}
                    getOptionLabel={(option) =>`${option?.razryad_short_name}`}
                    onChange={(event, value) => {
                        setSelectedRazryad(value);
                        setRazryad({...razryad, razryad_type_id:value?.razryad_id})
                           
                    }}
                    renderInput={(params) => <TextField {...params} label="Разряд"                        
                    />}
                />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                <DatePicker  variant="outlined"  value={selectedDate} label="Дата присвоения" onChange={(x, event) =>{
                    setSelectedDate(x);
                     setRazryad({...razryad, razryad_date_start:`${x.$y}-${x.$M+1}-${x.$D}`})}}/>
            </LocalizationProvider>
        <TextField fullWidth id="outlined-basic"  label="Приказ" value={razryad.razryad_prikaz} variant="outlined" name="prikaz" onChange={e=>setRazryad({...razryad, razryad_prikaz:e.target.value})}/>
        
        <TextField fullWidth  id="outlined-basic"  label="Организация" value={razryad.razryad_org} variant="outlined" name="org" onChange={e=>setRazryad({...razryad, razryad_org:e.target.value})}/>
        </Box>
        

        <Button  
                sx={{my:1}}
                fullWidth              
                variant="contained"
                key="addRazryad"
                onClick={AddRazryad}
            >Добавить разряд </Button>
            <Button  
                sx={{my:1}}
                fullWidth              
                variant="outlined"
                key="ClearRazryad"
                onClick={()=>ClearRazryad()}

            >Очистить </Button>

            <TableContainer sx={{  height: "300px", overflow: "auto"}} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>            
                            <TableCell align="center">Разряд</TableCell>
                            <TableCell align="center">Дата присовения</TableCell>
                            <TableCell align="center">Приказ</TableCell> 
                            <TableCell align="center">Удалить</TableCell>
                        </TableRow>
                        </TableHead>
                    <TableBody>  
                    {
            razryads?.map((r,i)=>(
              <TableRow
              key={r.razryad_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                    <TableCell wrap align="center">{i+1}</TableCell>
                    <TableCell wrap align="center">{r.razryad_short_name}</TableCell>
                    <TableCell wrap align="center">{r.razryad_date_start}</TableCell>
                    <TableCell wrap align="center">
                        {`${r.razryad_prikaz}, ${r.razryad_org}`}
                        </TableCell> 
                    <TableCell wrap align="center" size="medium">
                    <IconButton  aria-label="delete" 
                  onClick={()=>deleterazryad(r.razryad_id)} 
                  size="large"
                  sx={{minWidth:10}}>
                      <DeleteOutlineOutlinedIcon />
                  </IconButton>
                  </TableCell>
                    </TableRow>))}
                    </TableBody>
                    </Table>
                    </TableContainer></>
            default:
                return <Typography variant="h6">Выберите элемент из меню</Typography>;
        }
    };
    const [selectedItem, setSelectedItem] = useState('Личные данные');

    const handleListItemClick = (item) => {
        setSelectedItem(item);
    };
    const list = () => (
        <List>
            {menuItems.map((item, index) => (
                <ListItem button key={item.text} onClick={() => handleListItemClick(item.text)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="body1" sx={{ display: { xs: 'none', md: 'block' } }}>
                        {item.text}
                    </Typography>
                </ListItem>
            ))}
        </List>
    );
    return (       
        <Back sx={{width:{xs:"400",md:"900"}}}>        
        <Grid container columns={12}>
            <Grid item xs={2} md={4} sx={{borderRight: "0.3px solid black"}}>
            <Grid container columns={12}>
            <CssBaseline />
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CssBaseline />
            
            <Avatar onerror={'./uploads/users/no_avatar.jpg'} key={`uam`+contextValue.user_avatar_key} src={sportsmen.sportsmen_avatar_crop} sx={{
                   width: {xs:'40px', md:'100px'},
                  height: {xs:'40px', md:'100px'},
                  align: 'center',
                  borderRadius: {xs:'50%', md:'15%'},
                  objectFit: 'cover',
                  filter: 'brightness(1.1) contrast(1.2)',
                  border: '2px solid #ccc',
                }}/>
                 </Grid>
                <Grid item xs={12}>
                {list()}  
                </Grid>
                </Grid>
                </Grid>
            <Grid item xs={10} md={8} sx={{p:1}}>
                {renderContent()}
            </Grid>
        </Grid>     
          
        <Grid container columns={12} >
            <Grid item xs={12} md={12}>
            <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>  
            {sostav.length>0?
            <>
            <Typography>{`В составе(${sostav[0].sostav_type_name}): `}
            {sostav.map((s,i)=>(
                s.sportsmen_id!==sportsmen.sportsmen_id 
                && 
                <Button variant="text" onClick={()=>contextValue.setSportsmen(s.sportsmen_id)}>{s.FIO.replace("..",".")}</Button>))}
                </Typography>
            </>
            :
            <Typography>В составе не состоит </Typography>
            }
            
            </Grid>  
            </Grid> 
        </Back>      
     );
}
 
export default SportsmenPage;