 import "./styles/main.css"
  import "./App.css"
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/utils/ScrolToTop";
import Router from "./components/Router/Router";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { Portal } from "@mui/material";
import React from "react";
import {Context} from "./context";
import {Helmet} from "react-helmet";
import axios from "axios";
import {useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
  typography: {
    fontFamily: `'Times New Roman', Times, serif`,
    fontWeight: 250,
    fontStyle: 'normal'// Укажите желаемый шрифт
  },
  
});

function Transition(props) {
  return <Slide {...props} direction="down" />;
}

function App() {

  const [Msg, setMsg] =React.useState("Hello")
  const [typeMsg, setTypeMsg] =React.useState("success")
  const [open, setOpen] = React.useState(false);
  const[auth,setAuth]=useState(null);
  const [name,setName]=useState('');
  const [user_id,setUserId]=useState('');
  const [super_user,setSuperUser]=useState('');
  const [user_email,setUserEmail]=useState('');
  const [user_avatar,setUserAvatar]=useState('./uploads/users/no_avatar.jpg');
  const [user_avatar_crop,setUserAvatarCrop]=useState('./uploads/users/no_avatar.jpg');
  const [user_avatar_key,setUserAvatarKey]=useState(0);
  const [school,setSchool]=useState(
    {
      school_id:'',
      school_name:'',
      school_inn:'',
      school_adress:''
    });
  const [school_id,setSchoolID]=useState('');
  const [userType,setUserType]=useState('');
  const [trener,setTrener]=useState(
    {
      trener_id:'',
      trener_FIO:''
    });
  const [trener_id,setTrenerID]=useState('');
  const [result_id,setResultID]=useState('');
  const [select_trener,setSelectTrener]=useState(null);
  const [sportsmen,setSportsmen]=useState(null);
  const [order_member_id,setOrderMemberID]=useState(null);
  const [championat_sud_id,setChampionatSudID]=useState(null);
  const [championat_id,setChampionatID]=useState(null);
  const [championat,setChampionat]=useState(
    {
      championat_style_name:'',
      championat_type_name:'',
      championat_type_id:'',
      championat_name:'',
      championat_start_date:'',
      championat_end_date:'',
      championat_adress:'',
      school_name:''
    }); 
  

  function setOpenAlert () {
      setTransition(() => Transition);
      setOpen(true);
    };

  const closeAllert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  const [transition, setTransition] = React.useState(undefined);

  useEffect(()=>{
    if(user_id!==null)
      {
          if(user_avatar_crop==='./uploads/users/no_avatar.jpg') 
            setTimeout(setUserAvatarCrop(`./uploads/users/${user_id}/avatar_crop.png`),1500)
          if(user_avatar==='./uploads/users/no_avatar.jpg')
            setTimeout(setUserAvatar(`./uploads/users/${user_id}/avatar_full.png`),1500)

      }

  },[user_id,user_avatar_crop,user_avatar])

  useEffect(()=>{
      console.log(user_avatar_key)
      setUserAvatar('./uploads/users/no_avatar.jpg')
      setUserAvatarCrop('./uploads/users/no_avatar.jpg')
  },[user_avatar_key])

  useEffect(()=>{
    setChampionatID(JSON.parse(localStorage.getItem('championatID')))
    if(school_id!=='')
    {
      
      if(userType>0 && trener_id!==undefined)
      {
        axios.get(`/api/treners/trener/${trener_id}`)
      .then(res=>
        {  
          console.log(res.data)        
          setName(res.data[0].trener_family +" "+ res.data[0].trener_name)
          setTrener({...trener,trener_FIO:res.data[0].trener_FIO})
        })
        .catch(err=> console.log(err));
      }
     
        axios.post(`/api/schools/school/${school_id}`)
        .then(res=>
          { 
            console.log(res.data[0])
             if(userType===0)
            {
              setName(res.data[0].school_name)
            }
            setSchool({...school,
              school_id:res.data[0].school_id,
              school_name:res.data[0].school_name,
              school_inn:res.data[0].school_inn,
              school_adress:res.data[0].school_adress
            })
          })
          .catch(err=> console.log(err));
          
    }    
  },[school_id,trener_id])


  useEffect(()=>{
    
    if(championat_id!==null)
    {
          setChampionat({...championat,
            championat_style_name:JSON.parse(localStorage.getItem('championat_style_name')),
            championat_type_name:JSON.parse(localStorage.getItem('championat_type_name')),
            championat_type_id:localStorage?.getItem('championat_type_id')!=="undefined"?JSON.parse(localStorage?.getItem('championat_type_id')):"",
            championat_name:JSON.parse(localStorage.getItem('championat_name')),
            championat_start_date:JSON.parse(localStorage.getItem('championat_start_date')),
            championat_end_date:JSON.parse(localStorage.getItem('championat_end_date')),
            championat_adress:JSON.parse(localStorage.getItem('championat_adress')),
            school_name:JSON.parse(localStorage.getItem('school_name'))
          })
      }     
        
  },[championat_id])


  useEffect(()=>{
    if(user_id!==null)
    {
      setUserAvatar(`./uploads/users/${user_id}/avatar_full.png`)
      setUserAvatarCrop(`./uploads/users/${user_id}/avatar_crop.png`)
      console.log(localStorage)
          setChampionat({...championat,
            championat_style_name:JSON.parse(localStorage.getItem('championat_style_name')),
            championat_type_name:JSON.parse(localStorage.getItem('championat_type_name')),
            championat_type_id:localStorage.getItem('championat_type_id')!=="undefined"?JSON.parse(localStorage?.getItem('championat_type_id')):"",
            championat_name:JSON.parse(localStorage.getItem('championat_name')),
            championat_start_date:JSON.parse(localStorage.getItem('championat_start_date')),
            championat_end_date:JSON.parse(localStorage.getItem('championat_end_date')),
            championat_adress:JSON.parse(localStorage.getItem('championat_adress')),
            school_name:JSON.parse(localStorage.getItem('school_name'))
          })
      }       
  },[user_id])
  
  return (
<div className="App">
      <ThemeProvider theme={theme}>
      <Helmet titleTemplate="%s - ACROpro" defaultTitle="ACROpro">
        <meta name="description" content="Система автоматизации проведения соревнований по спортивной акробатике" />
      </Helmet>
      <ScrollToTop/>
      
      <Context.Provider value={
        {user_email,setUserEmail,
          user_id,setUserId,
          auth,setAuth,
          name,setName,        
          Msg,setMsg,
          typeMsg,setTypeMsg,
          open,setOpen,
          setOpenAlert,
          userType,setUserType,
          trener,setTrener,
          trener_id,setTrenerID,
          school,setSchool,
          school_id,setSchoolID,
          sportsmen,setSportsmen,
          select_trener,setSelectTrener,
          championat_id,setChampionatID,
          championat,setChampionat,
          order_member_id,setOrderMemberID,
          result_id,setResultID,
          championat_sud_id,setChampionatSudID,
          super_user,setSuperUser,
          user_avatar,setUserAvatar,
          user_avatar_crop,setUserAvatarCrop,
          user_avatar_key,setUserAvatarKey
        }}>

      <Router/>    

      </Context.Provider>
      <Footer sx={{my:1}}/> 
      <Portal>
                <Snackbar open={open} sx={{mt:25}} autoHideDuration={1500} onClose={closeAllert}  anchorOrigin={{ vertical:'top', horizontal:'center' }} TransitionComponent={transition}>
                        <Alert onClose={closeAllert} severity={typeMsg} sx={{ width: '100%' }}>
                        {Msg}
                        </Alert>
                </Snackbar>
      </Portal>
      </ThemeProvider>      
    </div>
  );
}

export default App;
