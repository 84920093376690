import { useContext,useEffect,useState } from "react";
import {Context} from "../../context";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormDialog from "../../ModalWindow/Dialog/FormDialog";
import axios from 'axios';
import SportsmenPage from "../../pages/SportsmenPage";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import Avatar from '@mui/material/Avatar';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AllSportsmensTable = (props) => {
  const [open, setOpen] = React.useState(false);  
  const [openDellete, setOpenDellete] = React.useState(false);
  const contextValue=useContext(Context)
  
    const[editSportsmenActive,seteditSportsmenActive]=useState(false)
    const[delSportsmenActive,setdelportsmenActive]=useState(false)
    const[trenerFlter,setTrenerFilter]=useState(null)
    const [find, setFind] =useState('')
    const [sportsmens, setSportsmens] =useState([])
    const [filtredsportsmens, setFiltredSportsmens] =useState([])
    const [treners, setTreners] =useState([])
    const [values,setValues] = useState ({
      id:'',
      fio:''
    })
    const [sostavID,setSostavID] = useState ({
      id:0,
      sostav_type_name:''     
    })
    function handleClickOpen(values,sostavID) {
      setSostavID(sostavID)
      setValues(values)
      setOpen(true);
    };
  
    function handleClickOpenDellete(values) {
      setValues(values)
      setOpenDellete(true);
    };

    const handleClose = () => {        
        setOpen(false);
    };
    const handleCloseDellete = () => {        
      setOpenDellete(false);
  };

    async function getTreners() {      
        let response = await axios.get(`/api/treners/my/${contextValue.school_id}`);
        let treners = response.data;// Don't need await here
        setTreners(treners?.filter(x=>x.archive===0 && x.trener===1));
    };
    
    async function getSportsmens() {      
      let response = await axios.post(`/api/sportsmens/all/${contextValue.school_id}`);
      let sportsmens = response.data;// Don't need await here
      console.log(response.data)
      if(props.type==="All") sportsmens=sportsmens?.filter(x=>x.sportsmen_archive===0)
      else if(props.type==="Archive") sportsmens=sportsmens?.filter(x=>x.sportsmen_archive===1)
      else if(props.type==="My") sportsmens=sportsmens?.filter(x=>x.trener_id===contextValue.trener_id && x.sportsmen_archive===0)
      setSportsmens(sportsmens);
  }
  useEffect(()=>
  {
    getTreners();
    getSportsmens();
  },[contextValue.setOpenAlert]);

  useEffect(()=>
  {setFiltredSportsmens(filterSportsmen)}
  ,[find,sportsmens,trenerFlter])


  const filterSportsmen = ()=>
  {
    let filtredSportsmens=sportsmens
    if(trenerFlter!==null) filtredSportsmens=filtredSportsmens.filter((s)=>s.trener_id===trenerFlter)

    return filtredSportsmens.filter((sportsmen)=>sportsmen.sportsmen_family.toLowerCase().trim().includes(find.toLowerCase().trim()))
  }
  function restoreSportsmen(values)
    {
      setValues(values)
        axios.post(`/api/sportsmens/sportsmen/restore/${values.id}`,values)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Спортсмен восстановлен")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg("Спортсмен восстановлен из архива")
              contextValue.setOpenAlert(true)
              // setaddSportsmenActive(true);
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
      
    }   
      function deletesportsmen()
      {
          axios.post(`/api/sportsmens/sportsmen/delete/${values.id}`,values)
          .then(res=>{
              console.log(res.data.Status)
              if(res.data.Status==="Спортсмен удален")
              {
                contextValue.setTypeMsg("success")
                contextValue.setMsg("Спортсмен удален")
                contextValue.setOpenAlert(true)
                // setaddSportsmenActive(true);
                handleCloseDellete();
              }
              else console.log(res.data.Status);
          })
          .catch(err =>alert(err))
                   
     
    }  


    function archivesportsmen()
    {
      console.log(values)
      console.log(sostavID)
        axios.post(`/api/sportsmens/sportsmen/archive/${values.id}`,values)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Спортсмен помещен в архив")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Спортсмен ${values.fio} в архиве`)
              contextValue.setOpenAlert(true)
              setdelportsmenActive(true);
              sostavID.id!==null?deletesostav():handleClose();
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))            
    }
    function deletesostav()
    {            
        axios.post(`/api/sostavs/delete/${sostavID}`,sostavID)
        .then(res=>{
            if(res.data.Status==="Состав добавлен в архив")
            {
              setdelportsmenActive(true);
              handleClose();
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
    } 

    function dateDif(date)
        {
            let date1=new Date();
            let date2=new Date(date.split('.')[2],date.split('.')[1],date.split('.')[0]);
            let diff = (date2.getDate() - date1.getDate()) / 30 +
            date2.getMonth() - date1.getMonth() +
            (12 * (date2.getFullYear() - date1.getFullYear()));
            // console.log(diff);
            return diff<3;
        }

    return (  
          <>
          {editSportsmenActive&&<FormDialog title={"Профиль спортсмена"} width={'md'} active={editSportsmenActive} setActive={seteditSportsmenActive} children={SportsmenPage} orders={filtredsportsmens} setOrders={setFiltredSportsmens}/>}
          <React.Fragment>            
            <Dialog
              open={openDellete}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseDellete}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Удалить спортсмена?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Удалить информацию о {values.fio}? Данные о спортсмене больше не будут доступны!"
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deletesportsmen}>Да</Button>
                <Button onClick={handleCloseDellete}>Нет</Button>                
              </DialogActions>
            </Dialog>
          </React.Fragment>
           <React.Fragment>            
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Отправить спортсмена в архив?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Информация о спортсмене: "{values.fio}" будет доступна во вкладке "Спортсмены в архиве".
                  {sostavID.id!==null && `Спортсмен является частью состава: "${sostavID.sostav_type_name}". Состав будет расформирован!`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={archivesportsmen}>Да</Button>
                <Button onClick={handleClose}>Нет</Button>                
              </DialogActions>
            </Dialog>
          </React.Fragment>


          <TextField fullWidth name="family" label="Поиск спортсмена" id="fullWidth" sx={{my:2}} onChange={e=>setFind(e.target.value)}/>
          
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>    
                <TableCell></TableCell>          
                <TableCell align="center">ФИО</TableCell>
                <TableCell align="center">Дата рождения</TableCell>
                <TableCell align="center">Разряд</TableCell>
                <TableCell align="center">Вид</TableCell>
                <TableCell align="center">
                  {
                    treners.length>1?
                    <Autocomplete
                    // id="trener"
                    // name='selecttrenerfilter'
                    freeSolo
                    options={treners}
                    inputProps={{
                        autoComplete: 'off'
                    }}                           
                    getOptionLabel={(option) =>`${option?.FIO}`}
                    onChange={(event, value) => {
                        value===null?
                        setTrenerFilter(null)
                        :setTrenerFilter(value?.trener_id)
                    }}
                    renderInput={(params) => <TextField {...params} label="Тренер"                        
                    />}
                    />
                  :"Тренер"       
                  }                  
                  </TableCell>
                <TableCell align="center" minWidth="20px"></TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
            {
            filtredsportsmens?.map((sportsmen,i)=>(
              <TableRow
              key={sportsmen.sportsmen_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell component="th" scope="row">{i+1}</TableCell>
            <TableCell wrap align="center">
                <Avatar onerror={'./uploads/users/no_avatar.jpg'} src={sportsmen.sportsmen_avatar_crop===undefined?`./uploads/sportsmens/${sportsmen.sportsmen_id}/avatar_crop.png`:sportsmen.sportsmen_avatar_crop} style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          objectFit: 'cover',
          filter: 'brightness(1.1) contrast(1.2)',
          border: '2px solid #ccc',
        }}/>              
                </TableCell> 
            <TableCell wrap align="center">
              {`${sportsmen.sportsmen_family} ${sportsmen.sportsmen_name} ${sportsmen.sportsmen_otchestvo}`}              
              </TableCell> 
             <TableCell wrap align="center">{`${sportsmen.sportsmen_age}`}</TableCell> 
             <TableCell  sx={{
                                whiteSpace: "pre-wrap"
                                
                                }} align="center">
                                  <Typography variant="caption"
                                display="block" 
                                color={sportsmen?.razryad_name!==null&&(dateDif(sportsmen.razryad_date_end)&&"red")}
                                sx={{                                  
                                fontFamily: 'Poppins',
                                whiteSpace: "pre-wrap"                                           
                                }} align="center">
                    {sportsmen?.razryad_name!==null?`${sportsmen?.razryad_name?.toString()}\n(до ${sportsmen?.razryad_date_end?.toString()})`:"Без разряда"}
              </Typography>
              </TableCell> 
             <TableCell wrap align="center">{sportsmen.sostav_type_name?.toString()}</TableCell> 
             {sportsmen.trener_id!=null?
             <TableCell wrap align="center">{treners[treners?.findIndex((t)=>t.trener_id===sportsmen.trener_id)]?.FIO}</TableCell> 
              :
              <TableCell wrap align="center">{"Тренер не назначен"}</TableCell> 
             }
             
              {props.type!=="Archive"?<TableCell wrap align="center">
                <IconButton  aria-label="edit" onClick={()=>{contextValue.setSportsmen(sportsmen.sportsmen_id);seteditSportsmenActive(true)}}>
                    <EditOutlinedIcon />
                </IconButton>
                <IconButton  aria-label="delete" 
                onClick={()=>handleClickOpen({...values, 
                  id:sportsmen.sportsmen_id, 
                  fio:`${sportsmen.sportsmen_family} ${sportsmen.sportsmen_name} ${sportsmen.sportsmen_otchestvo}`},
                  {...sostavID,id:sportsmen.sostav_id,sostav_type_name:sportsmen.sostav_type_name
                })}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
                
                </TableCell> 
                :
                <TableCell wrap align="center">
                <IconButton  aria-label="restore" onClick={()=>restoreSportsmen({...values, id:sportsmen.sportsmen_id, fio:`${sportsmen.sportsmen_family} ${sportsmen.sportsmen_name} ${sportsmen.sportsmen_otchestvo}`})}>
                    <RestoreOutlinedIcon />
                </IconButton>
                <IconButton  aria-label="delete" onClick={()=>handleClickOpenDellete({...values, id:sportsmen.sportsmen_id, fio:`${sportsmen.sportsmen_family} ${sportsmen.sportsmen_name} ${sportsmen.sportsmen_otchestvo}`})}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
                
                </TableCell>}          
                  </TableRow>))}          
            </TableBody>
      </Table>
    </TableContainer>    

          </>
         
      );
}
 
export default AllSportsmensTable;