
import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import { useContext,useEffect,useState } from "react";
import {Context} from "../../context";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import "./style.css";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const AddSportsmen = (props) => {
    const contextValue=useContext(Context)
    const [selectedDate,setSelectedDate]=useState(null)
    const [values,setValues] = useState ({
        family:'',
        name:'',
        otchestvo:'',
        age: '',
        gender:null,
        trener_id:contextValue.trener_id
        
    })
 
    
    let can=true;

    useEffect(()=>
    {
        console.log(values)
    },[values])

    function clear()
    {
        setSelectedDate(null);
        setValues({...values,family:'',
        name:'',
        otchestvo:'',
        age: '',
        gender:null,
        trener_id:contextValue.trener_id})
    }
    const [open, setOpen] = useState(false);
    function handleSubmit(event)
    {
        can=true;
        contextValue.setMsg('');
            checkInput();
            if(!open && can)
             {
                setOpen(true)
                event.preventDefault();
                axios.post(`/api/sportsmens/sportsmen/add/${contextValue.school_id}`,values)
                .then(res=>{
                    if(res.data.Status==="Спортсмен добавлен")
                    {
                        contextValue.setTypeMsg("success")
                        contextValue.setMsg("Спортсмен добавлен")
                        contextValue.setOpenAlert(true)
                        clear();
                        setTimeout(props.setActive(false), 5000);
                        setOpen(false)     
                    }
                    else alert(res.data.Error);
                })
                .catch(err =>alert(err));
            }
    }




    function checkInput()
    {         
         if(values.family==='')
         {
            contextValue.setMsg("Введите фамилию")
            can=false
         }
         else if(values.name==='')
         {
            contextValue.setMsg("Введите имя")
            can=false
         }    
         else if(values.age==='')
         {
            contextValue.setMsg("Введите дату рождения")
            can=false
         }       
         else if(values.gender===null)
         {
            contextValue.setMsg("Выберите пол ребенка")
            can=false
         }  
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
      
        


    return (           
        <div className="p-3 bg-white">   
             <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
           
            <form onSubmit="return false">
           
                            <Box
                                sx={{
                                    display: 'grid',                    
                                    gridAutoColumns: '1fr',
                                    gap: 2,
                                    gridTemplateColumns: 'repeat(1, 1fr)',
                                    alignContent:'center',
                                    alignItems:'center'               
                                }}
                                > 
                        <TextField id="outlined-basic" label="Фамилия" value ={values.family} variant="outlined" name="family" onChange={e=>setValues({...values, family:e.target.value})}/>
                        
                        <TextField id="outlined-basic" label="Имя" variant="outlined" value ={values.name} name="name" onChange={e=>setValues({...values, name:e.target.value})}/>

                        <TextField id="outlined-basic" label="Отчество" variant="outlined" value ={values.otchestvo} name="otchestvo" onChange={e=>setValues({...values, otchestvo:e.target.value})}/>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <DatePicker
        variant="outlined"
        label="Дата рождения"
        value={selectedDate}
        onChange={(newValue) => {
          setSelectedDate(newValue);
          if (newValue) {
            // Форматирование даты в формате ДД.ММ.ГГГГ
            // const formattedDate = `${String(newValue.$D).padStart(2, '0')}.${String(newValue.$M + 1).padStart(2, '0')}.${newValue.$y}`;
            const formattedDate = `${String(newValue.$y).padStart(2, '0')}-${String(newValue.$M + 1).padStart(2, '0')}-${newValue.$D}`
            setValues({ ...values, age: formattedDate });
          }
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
                        <ToggleButtonGroup
                                color="primary"
                                value={values.gender}
                                exclusive
                                onChange={(event, newAlignment) => {
                                    setValues({...values, gender:newAlignment})}}
                                aria-label="Platform"
                                sx={{my:1,  
                                    display: 'grid',                    
                                    gridAutoColumns: '1fr',
                                    gridTemplateColumns: 'repeat(2, 1fr)'            
                                }}
                                >
                                <ToggleButton value='м'>Мальчик</ToggleButton>
                                <ToggleButton value='ж'>Девочка</ToggleButton>
                        </ToggleButtonGroup>
                        <Button                
                            variant="contained"
                            key="AddSportsmen"
                            disabled={open}
                            onClick={handleSubmit}
                        >Добавить </Button>
                        <Button                
                            variant="contained"
                            key="Cancel"
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button>                       
                        
                        </Box>
                
              

            </form>
           
        </div>

      );
}
 
export default AddSportsmen;