import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import FormDialog from "../ModalWindow/Dialog/FormDialog"
import Result from "./Result";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Button } from "@mui/material";
import AllResult from "./AllResults";
import Grid from '@mui/material/Grid';

import Stack from '@mui/material/Stack';


const ChampionatResults = (props) => {
        const contextValue=useContext(Context)
        const[resultOrderMember,setResultOrderMember]=useState(false)
        const[resultsPrint,setResultsPrint]=useState(false)
        const[resultsPrintSolo,setResultsPrintSolo]=useState(false)
        const[results,setResults]=useState([])
        const [select,setSelect]=useState(null)
        const [selectHeader,setSelectHeader]=useState(null)
        useEffect(()=>
        {
                success_order()
        },[])

        useEffect(()=>
                {
                        if(select!==null)
                        setResultOrderMember(true)
                },[select])

        function success_order()
        {            
            axios.get(`/api/results/all/${contextValue.championat_id}`)
            .then(res=>{
                console.log(res.data)
                res.data?.length>0&&setSelectHeader(res.data[0].header)
                setResults(res.data)
            })
            .catch(err =>alert(err))
        } 
    
    return (
    <>
        {select!==null && <FormDialog title='Результаты участника' width='lg' active={resultOrderMember} setActive={setResultOrderMember} children={Result} orders={select}/>}
        {results.length!==0 && 
        <>
        {resultsPrint && <FormDialog title='Результаты соревнования' width='lg' active={resultsPrint} setActive={setResultsPrint} children={AllResult} orders={[results,props.suds]}/>}
        {resultsPrintSolo && <FormDialog title={`Результаты группы ${selectHeader}`} width='lg' active={resultsPrintSolo} setActive={setResultsPrintSolo} children={AllResult} orders={[results.filter((x)=>x.header===selectHeader),props.suds]}/>}
        <Stack direction='row' justifyContent='right'>
        <Button onClick={()=>setResultsPrint(true)}>Печать всех</Button>
        <Button onClick={()=>setResultsPrintSolo(true)}>Печать выбранных</Button>   
        </Stack> 
        <Grid container spacing={3}>
        <Grid my={1} item xs={selectHeader!==null?3:12}>
        {
        Array.from(new Set(results?.map((obj)=>obj.header))).map((o,i)=>(  
                <Button  sx={{margin:"3px"}} variant={o===selectHeader?"contained":"outlined"} fullWidth onClick={()=>setSelectHeader(o)}>{o} </Button>))
        } 
        </Grid> 
        {selectHeader!==null&&<Grid item xs={9}>
        {
                <>
                <Stack direction='row' justifyContent='center'>
                <Typography
                variant="h6"
                sx={{
                  my:1,
                  fontFamily: 'Poppins',
                  color: 'inherit',
                  whiteSpace: "pre-wrap"
                }}>
                {selectHeader}
                </Typography>
                </Stack>
        
                <TableContainer component={Paper}>
                <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                                      '& .MuiTableCell-sizeSmall': {
                                      padding: '1px',
                                      },
                                      }}
                              > 
                 <TableHead>
                   <TableRow>
                     <TableCell>Место</TableCell>         
                     <TableCell align="center">ФИО</TableCell>
                     {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell align="center">Упражнения</TableCell>}
                     {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell align="center">Оценки за упражнения</TableCell>}
                     <TableCell align="center">Результат</TableCell>
                   </TableRow>
                 </TableHead>
                                <TableBody>
                                 {results?.filter((x)=>x.header.includes(selectHeader))?.map((om,i)=>( 
                                      <TableRow 
                                //       key={om.order_member_id}
                       sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                     >
                     <TableCell component="th" scope="row">
                             {om.place}
                     </TableCell>
                     <TableCell sx={{
                             whiteSpace: "pre-wrap"
                                    }} align="center">
                                    <p>{contextValue.championat.championat_style_name==="Парно-групповое"?om.sportsmens:om.FN_KPN}</p>           
                            </TableCell> 
                            {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell sx={{
                                    whiteSpace: "pre-wrap"
                             }} align="center">
                                    <p>{om.composition_type_name}</p>           
                            </TableCell>}
                            {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell sx={{
                                    whiteSpace: "pre-wrap"
                                    }} align="center">
                                    <p>{om.score}</p>           
                            </TableCell> }
                            <TableCell sx={{
                                    whiteSpace: "pre-wrap"
                                    }} align="center">
                                    <p>{parseFloat(om.full_score).toFixed(2)}</p>           
                            </TableCell>   
                                   

                            {props.active===undefined && <TableCell align="center">
                                <Tooltip title="Результаты">                                      
                                        <IconButton  aria-label="edit" onClick={()=>setSelect(om.order_member_id)}>
                                <ReceiptLongOutlinedIcon />                                        
                                </IconButton>
                                </Tooltip>
                                </TableCell>} 
                       </TableRow>))}          
                            </TableBody>
                                </Table>
                    </TableContainer> 
                    </>
                } 
                </Grid> }
                </Grid> 
        </>}</>
     )
}
 
export default ChampionatResults;