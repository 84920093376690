import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import "./../styles/main.css"
import Button from '@mui/material/Button';
import React from "react";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import logo from "./../img/Logo.png"
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import html2pdf from 'html2pdf.js'
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const OrderPrint = (props) => {
  const [open, setOpen] = useState(false); 
    const contextValue=useContext(Context)
    const [order_id, setOrderID] =useState(null)
    const [order, setOrder] =useState([])
    const [suds, setSuds] =useState([])
    const [archive, setArchive] =useState([])
    const componentRef = useRef();


      const downloadPdf = async () => {
        const fileName = 'test.pdf';
        const blob = await pdf(<ComponentToPrint />).toBlob();
        saveAs(blob, fileName);
      }
  const handlePDF = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@page { size: auto; margin: 0mm; }",
    contentStyle: { transform: 'scale(0.8)', transformOrigin: 'top left' },
    onPrintError: (error) => console.log(error),
    print: async (printIframe) => {
      console.log(printIframe);
      const document = printIframe.contentDocument;
      if (document) {
        const ticketElement = document.getElementsByClassName("ticket")[0];
        ticketElement.style.display = "block";
                
        // Установите стиль для контейнера, чтобы он корректно располагался
        ticketElement.style.width = '100%';
        ticketElement.style.overflow = 'visible';

        const options = {
            margin: 10, // Задайте отступы по краям
            filename: "ticket.pdf",
            jsPDF: {
                unit: "pt", // Используйте точки для более точной настройки
                format: "a4", // Используйте стандартный формат A4
                orientation: "landscape" // Альбомная ориентация
            },
            html2canvas: {
                scale: 2 // Увеличьте масштаб для улучшения качества текста
            }
        };
        const exporter = new html2pdf(ticketElement, options);
        await exporter.getPdf(options);
      }
    }, 
  }); 
  function removeEverySecondNewline(str) {

    return str
        .split('\n') // Разделяем строку по символу новой строки
        .map((s,i)=> s+=i%2!==0?'\n':', ') // Оставляем только четные индексы
        .join(''); // Объединяем обратно в строку с новыми строками
}
  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} className="ticket table-to-print" style={{whiteSpace: 'pre-wrap' }}>
             <TableContainer sx={{ my:1}} >
        <Table className="custom-table" style={{ width: "100%", tableLayout: "fixed"}}  aria-label="sticky table" size='small'  sx={{
                  '& .MuiTableCell-sizeSmall': {
                    padding: '1px',
                  },
                }}
              > 
                      <TableBody> 
                      <TableRow>
                        <TableCell align='center' style={{width: 100, border:0}} rowSpan={4}>
                          <img width={100} src={logo}></img> 
                        </TableCell>
                        <TableCell style={{width: '60%', border:0}} align='center' rowSpan={4}>
                            
                        </TableCell>
                        <TableCell style={{width: '30%', border:0, fontFamily: 'inherit'}} align='center'>
                            <Typography fontSize={18}>«УТВЕРЖАЮ»</Typography>
                        </TableCell>
                      </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0, fontFamily: 'inherit'}} align='left'>
                            <Typography fontSize={18}>{values.resolution_dolj?.trim()!==''?values.resolution_dolj:'________________________________'}</Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0, fontFamily: 'inherit'}} align='fit'>
                            <Typography fontSize={18}>{values.resolution_fio?.trim()!==''?`_____________${values.resolution_fio}`:'________________________________'}</Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0 , fontFamily: 'inherit'}} align='center'>
                            <Typography fontSize={18}>{`« ___ » _____________ ${new Date().getFullYear()}г.`} </Typography>
                          </TableCell>
                          </TableRow>
                      </TableBody>
                      </Table>
                      </TableContainer>
                      <TableContainer>
                      <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                  '& .MuiTableCell-sizeSmall': {
                    padding: '1px',
                  },
                }}
              > 
                      <TableBody> 
                        <TableRow>
                          <TableCell style={{ border:0}} align='center'>
                            <Typography fontSize={18}><strong>ЗАЯВКА</strong></Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0}} align='center'>
                            <Typography fontSize={18}> <u>от {` ${values.region}`}</u> (области, края, Республики)</Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0}} align='center'>
                            <Typography fontSize={18}>на участие в {`${values.championat_name}`},</Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0}} align='center'>
                            <Typography fontSize={18}>проводимом {`${contextValue.championat.championat_start_date} - ${contextValue.championat.championat_end_date}`} в городе {` ${values.city} `}.</Typography>
                          </TableCell>
                          </TableRow>
                      </TableBody>
                      </Table>
                      </TableContainer>  
                          <TableContainer sx={{ my:1}}>
                          <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                                      '& .MuiTableCell-sizeSmall': {
                                      padding: '1px',
                                      },
                                }}
                              > 
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ border: 1 ,
                                            fontSize: '12px'}}>№</TableCell>            
                                    <TableCell align="center" sx={{ border: 1 ,
                                            fontSize: '12px'}}>Фамилия, имя, отчество</TableCell>
                                    <TableCell align="center" sx={{ border: 1, width: '70px'  }}>Функция</TableCell>                                   
                                    {contextValue.championat.championat_style_name==="Парно-групповое" && <TableCell align="center" sx={{ border: 1, width: '70px',
                                            fontSize: '12px'  }}>Дисциплина акробатики</TableCell>}
                                    <TableCell align="center" sx={{ border: 1,
                                            fontSize: '12px' }}>Год рождения</TableCell>
                                    <TableCell align="center" sx={{ border: 1, width: '200px',
                                            fontSize: '12px'}}>Ведомство</TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center" sx={{ border: 1, width: '70px' ,
                                            fontSize: '12px' }}>Разряд имеет</TableCell>}
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center" sx={{ border: 1, width: '70px',
                                            fontSize: '12px'  }}>Выступает по разряду</TableCell>}
                                    <TableCell align="center" sx={{ border: 1, width: '200px',
                                            fontSize: '12px'}}>Ф.И.О. тренера</TableCell>
                                    <TableCell align="center" sx={{ border: 1, width: '100px',
                                            fontSize: '12px' }}>Виза врача, печать</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {order.length>0 && order?.map((o,i)=>(
                                      <TableRow>
                                    <TableCell component="th" scope="row" sx={{ border: 1,
                                            fontSize: '12px' }}>
                                            {i+1}
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">
                                            {o.FIO}            
                                    </TableCell>  
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">
                                            Спортсмен           
                                    </TableCell>  
                                    {contextValue.championat.championat_style_name==="Парно-групповое" && 
                                    <TableCell align="center" sx={{ border: 1,
                                            fontSize: '12px' }}>
                                            {o.sostav_type_name_short}                    
                                    </TableCell>}              
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">           
                                            {o.sportsmen_age}                    
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">           
                                            {o.school_name}                    
                                    </TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">            
                                            {o.razryad_name}                      
                                    </TableCell>  } 
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center" sx={{ border: 1,
                                            fontSize: '12px' }}>             
                                            {o.razryad_short_name}                          
                                    </TableCell> }
                                    
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: (theme) => `${12 - ((o.treners?.split('\n')?.length/4) || 0)}px`
                                            }} align="center"> 
                                              {removeEverySecondNewline(o.treners)}  
                                    </TableCell>          
                                    <TableCell sx={{minWidth:"80px", border: 1,
                                            fontSize: '12px' }} align="center">
                                    
                                      </TableCell>
                                      </TableRow>))} 
                                      
                                      {suds?.filter(x=>x.sud_role_id>0)?.length>0 && suds?.filter(x=>x.sud_role_id>0)?.map((o,i)=>(
                                      <TableRow>
                                    <TableCell component="th" scope="row" sx={{ border: 1,
                                            fontSize: '12px' }}>
                                            {i+1+order?.length}
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">
                                            {o.FIO}            
                                    </TableCell>  
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">
                                            {o.sud_role_id===2?'Секретарь':'Судья'}           
                                    </TableCell>  
                                    {contextValue.championat.championat_style_name==="Парно-групповое" && 
                                    <TableCell align="center" sx={{ border: 1,
                                            fontSize: '12px' }}>
                                            {o.sud_categ_name}                    
                                    </TableCell>}              
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">           
                                            {/* {o.sportsmen_age}                     */}
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">           
                                            {o.school_name}                    
                                    </TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">            
                                            {/* {o.razryad_name}                       */}
                                    </TableCell>  } 
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center" sx={{ border: 1,
                                            fontSize: '12px' }}>             
                                            {o.sud_categ_name}                          
                                    </TableCell> }
                                    
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center"> 
                                              {/* {o.treners}   */}
                                    </TableCell>          
                                    <TableCell sx={{minWidth:"80px", border: 1,
                                            fontSize: '12px' }} align="center">
                                    
                                      </TableCell>
                                      </TableRow>))}   
                                      {suds?.filter(x=>x.sud_role_id===0)?.length>0 && suds?.filter(x=>x.sud_role_id===0)?.map((o,i)=>(
                                      <TableRow>
                                    <TableCell component="th" scope="row" sx={{ border: 1,
                                            fontSize: '12px' }}>
                                            {i+1+order?.length+suds?.filter(x=>x.sud_role_id>0)?.length}
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">
                                            {o.FIO}            
                                    </TableCell>  
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '8px'
                                            }} align="center">
                                            Представитель команды           
                                    </TableCell>  
                                    {contextValue.championat.championat_style_name==="Парно-групповое" && 
                                    <TableCell align="center" sx={{ border: 1,
                                            fontSize: '12px' }}>
                                            {/* {o.sud_categ_name}                     */}
                                    </TableCell>}              
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">           
                                            {/* {o.sportsmen_age}                     */}
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">           
                                            {o.school_name}                    
                                    </TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center">            
                                            {/* {o.razryad_name}                       */}
                                    </TableCell>  } 
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center" sx={{ border: 1,
                                            fontSize: '12px' }}>             
                                            {/* {o.sud_categ_name}                           */}
                                    </TableCell> }
                                    
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap",
                                            border: 1,
                                            fontSize: '12px'
                                            }} align="center"> 
                                              {/* {o.treners}   */}
                                    </TableCell>          
                                    <TableCell sx={{minWidth:"80px", border: 1,
                                            fontSize: '12px' }} align="center">
                                    
                                      </TableCell>
                                      </TableRow>))}    
                                    </TableBody>
                              </Table>
                            </TableContainer> 
                            <TableContainer>
                      <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                  '& .MuiTableCell-sizeSmall': {
                    padding: '1px',
                  },
                }}
              > 
                      <TableBody> 
                        <TableRow>
                          <TableCell style={{ border:0}} align='left'>
                            <Typography fontSize={18}>К соревнованиям допущено _______________________________________________________________(прописью) спортсменов</Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0}} align='left'>
                            <Typography fontSize={18}>{`« ___ » _____________ ${new Date().getFullYear()}г. `} Врач подпись: ________________(расшифровка) ____________</Typography>
                          </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell style={{ border:0}} align='left'>
                            <Typography fontSize={18}>Печать врачебно-физкультурного диспансера</Typography>
                          </TableCell>
                          </TableRow>                          
                      </TableBody>
                      </Table>
                      </TableContainer> 
        </div>
    );
});
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    useEffect(()=>
      {
        if(contextValue.championat_id!==null)
        {
          
        axios.post(`/api/orders/get_order_id/${contextValue.championat_id}/${contextValue.school_id}`)
        .then(res=>{
          console.log(res.data.Status)
          if(res.data.Status==="Получен идентификатор заявки")
          {                            
             console.log("Получен идентификатор заявки: "+res.data.order_id)
             console.log(res.data)
             setOrderID(res.data.order[0].order_id)
             setArchive(res.data.order[0].order_archive) 
             setValues({...values,
              resolution_dolj:res.data.order[0].resolution_dolj===null?"":res.data.order[0].resolution_dolj,
              resolution_fio:res.data.order[0].resolution_fio===null?"":res.data.order[0].resolution_fio,
              region:res.data.order[0].region===null?JSON.parse(contextValue.school.school_adress)?.data.city:res.data.order[0].region,
              championat_name:res.data.order[0].championat_name===null?contextValue.championat.championat_name:res.data.order[0].championat_name,
              city:res.data.order[0].city===null?JSON.parse(contextValue.championat.championat_adress)?.data.city:res.data.order[0].city,
             })
          }
          else alert(res.data.Error);})
        .catch(err=>console.log(err)) 
      }
    },[contextValue.championat_id]);


      useEffect(()=>
      {
        if(order_id!==null)
          contextValue.championat.championat_style_name!=="Парно-групповое"?
             axios.post(`/api/orders/get_order_sportsmens_print/${order_id}`)
              .then(res=>{
                  setOrder(res.data)                           
                  console.log("Заявка получена")  
                  console.log(res.data)
                })
              .catch(err=>console.log(err))  
            :
            axios.post(`/api/orders/get_order_sostavs_print/${order_id}`)
            .then(res=>{
                setOrder(res.data)                           
                console.log("Заявка получена")  
                console.log(res.data)
              })
            .catch(err=>console.log(err))  
        
      },[order_id,contextValue.setOpenAlert]);

      useEffect(()=>
        {
          if(order_id!==null)
               axios.get(`/api/suds/print/${contextValue.championat_id}`)
                .then(res=>{
                    setSuds(res.data)                           
                    console.log("Судьи получены")  
                    console.log(res.data)
                  })
                .catch(err=>console.log(err))  
          
        },[order_id,contextValue.setOpenAlert]);



    const [values,setValues]=useState(
      {
        resolution_dolj:'',
        resolution_fio:'',
        region:JSON.parse(contextValue.school.school_adress)?.data.city,
        championat_name:contextValue.championat.championat_name,
        city:JSON.parse(contextValue.championat.championat_adress)?.data.city,
      })
      useEffect(()=>{console.log(values)},[values])

   function SaveOrder()
    {
      if(order_id!==null)
           axios.post(`/api/orders/update_order/${order_id}`, values)
            .then(res=>{                      
                console.log("Данные сохранены")  
              })
            .catch(err=>console.log(err))  
    }
    return (       
        <>
            <Helmet>
        <title>Печать заявки</title>
        <meta name="description" content="Печать заявки" />
      </Helmet>        
            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                     
                        <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                            <CircularProgress color="inherit" />
                            </Backdrop>
                        <Box fullWidth sx={{ padding:3, flexGrow: 1 }}>
                        <Grid sx={{my:1}} container spacing={2} columns={12} alignItems={"left"} alignContent={"center"}>
                        <Grid item xs={12} md={6}><TextField fullWidth id="outlined-basic" label="Утверждает - должность" value={values.resolution_dolj} variant="outlined" name="resolution_dolj" onChange={e=>setValues({...values, resolution_dolj:e.target.value})}/></Grid>
                        <Grid item xs={12} md={6}><TextField fullWidth id="outlined-basic" label="Утверждает - ФИО " value={values.resolution_fio} variant="outlined" name="championst_name" onChange={e=>setValues({...values, resolution_fio:e.target.value})}/></Grid>
                        <Grid item xs={12} md={12}><TextField fullWidth id="outlined-basic" label="Название соревнования" value={values.championat_name} variant="outlined" name="championst_name" onChange={e=>setValues({...values, championat_name:e.target.value})}/></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth id="outlined-basic" label="От - Регион" variant="outlined" value={values.region} name="championst_name" onChange={e=>setValues({...values, region:e.target.value})}/></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth  id="outlined-basic" label="Город проведения" variant="outlined" value={values.city} name="championst_name" onChange={e=>setValues({...values, city:e.target.value})}/></Grid>
                        </Grid>
                        <Grid sx={{my:1}} container spacing={2} columns={12} alignItems={"left"} alignContent={"center"}>
                        <Grid item xs={12} md={3}><Button fullWidth variant="contained" color="primary" onClick={downloadPdf}>Сохранить как PDF</Button></Grid>
                        <Grid item xs={12} md={3}><Button fullWidth variant="contained" color="primary" onClick={handlePrint}>Печать</Button></Grid>
                        <Grid item xs={12} md={3}><Button fullWidth variant="contained" color="primary" onClick={()=>SaveOrder()}>Запомнить</Button></Grid>
                        </Grid>
                        </Box>
                        <ComponentToPrint ref={componentRef} />        

                        </div>
                    </div>
                </div>
          </>  
     )
}
 
export default OrderPrint;