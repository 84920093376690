import React, { useState, useCallback, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage'; // Импортируйте вашу функцию для обрезки изображения

import { Button, Container, Typography, Snackbar, Alert } from '@mui/material';
import axios from "axios";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {Context} from "../context";
import {useContext} from "react";

const AvatarPreviewDialog = ({props, open, handleClose,fileName, type, preview, selectedFile, user}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const contextValue=useContext(Context) 
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(()=>{console.log(props)},[props])

  const handleUpload = async () => {
    setLoading(true)
    if (croppedAreaPixels) {
        const croppedImage = await getCroppedImg(preview, croppedAreaPixels);
        const formData = new FormData();
              formData.append('image', croppedImage, `avatar_crop.png`);

              console.log(`/upload_avatar/${type}-${type==='users'?contextValue.user_id:contextValue.sportsmen}-crop`)
              try {
                const response = await 
              fetch(`/upload_avatar/${type}-${type==='users'?contextValue.user_id:contextValue.sportsmen}-crop`, {
                method: 'POST',
                body: formData,
              });
        
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              
              type==='users'?contextValue.setUserAvatarCrop(URL.createObjectURL(croppedImage)):user.sportsmen_avatar_crop=URL.createObjectURL(croppedImage)
            } catch (error) {
              console.error('Error uploading image:', error);
            }
            try {
                console.log(`/upload_avatar/${type}-${type==='users'?contextValue.user_id:contextValue.sportsmen}-full`)
                const formData = new FormData();
                    formData.append('image', selectedFile, `avatar_full.png`);
                const response = await 
              fetch(`/upload_avatar/${type}-${type==='users'?contextValue.user_id:contextValue.sportsmen}-full`, {
                method: 'POST',
                body: formData,
              });
        
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              
              type==='users'?contextValue.setUserAvatar(preview):user.sportsmen_avatar=preview
              handleClose()
            } catch (error) {
              console.error('Error uploading image:', error);
            }
            // contextValue.setTypeMsg("success")
            // contextValue.setMsg(`Фотография загружена`)            
            // contextValue.setOpenAlert(true)        
            setLoading(false)
            // contextValue.setUserAvatarKey(prevState=>prevState+1);
}}

const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
};


  return (
    <Dialog open={open} onClose={handleClose}>
    <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
      </Snackbar>
      <DialogTitle>Предпросмотр</DialogTitle>
      <DialogContent>
        {preview && (
          <div style={{ position: 'relative', width: '100%', height: '300px' }}>
            <Cropper
              image={preview}
              crop={crop}
              zoom={zoom}
              aspect={1} // Соотношение сторон 1:1
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Отмена
        </Button>
        <Button onClick={handleUpload} color="primary">
          Загрузить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarPreviewDialog;