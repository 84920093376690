import { createCanvas } from 'canvas';

const getCroppedImg = (imageSrc, pixelCrop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = createCanvas(pixelCrop.width, pixelCrop.height);
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
  
        // Преобразуем canvas в Blob
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob); // возвращаем Blob вместо Data URL
          } else {
            reject(new Error('Canvas is empty'));
          }
        }, 'image/png');
      };
  
      image.onerror = (error) => {
        reject(error);
      };
    });
  };

export default getCroppedImg;